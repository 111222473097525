<template>
  <div class="MyTeacherCourse">
    <table-list
      title="我的课程列表"
      :loading="loading"
      :search-form="searchForm"
      :data="myCourseList"
      :columns="columns(this)"
      :operates="operates(this)"
      :options="{ operatesWidth: 200 }"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = [
  {
    label: '课程',
    prop: 'productName',
  },
  {
    placeholder: '课程类型',
    type: 'select',
    prop: 'courseType',
    children: [
      { value: 0, label: '录播课' },
      { value: 1, label: '直播课' },
    ],
  },
  {
    placeholder: '课程状态',
    type: 'select',
    prop: 'publishStatus',
    children: [
      { value: 0, label: '待发布' },
      { value: 1, label: '已上架' },
    ],
  },
  {
    label: '上架时间',
    type: 'picker',
    prop: 'dateTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'productPic',
    label: '课程封面',
    render: (h, { row }) => {
      return [
        row.productPic
          ? h('img', {
              domProps: { src: row.productPic },
              style: 'width:80px;height:50px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'productName',
    label: '课程名称',
    minWidth: 110,
  },
  {
    prop: 'courseTypeExplain',
    label: '类型',
    minWidth: 100,
  },
  {
    prop: 'retail.totalNumber',
    label: '销量',
    sortable: 'sortable',
    minWidth: 130,
    render: (h, { row }) => {
      return h('div', [
        h('div', '零售：' + row.retail.totalNumber),
        h('div', '按人数购买：' + row.batchBuy.totalNumber),
        h('div', '按包年不限量：' + row.buyOut.totalNumber),
      ])
    },
  },
  {
    prop: 'retail.totalAmount',
    label: '销售金额',
    // sortable: 'sortable',
    minWidth: 100,
    align: 'right',
    render: (h, { row }) => {
      return h('div', [
        h('div', row.retail.totalAmount),
        h('div', row.batchBuy.totalAmount),
        h('div', row.buyOut.totalAmount),
      ])
    },
  },
  {
    prop: 'totalAmount',
    label: '总销售金额',
    sortable: 'sortable',
    minWidth: 120,
    align: 'right',
  },
  {
    prop: 'pageViews',
    label: '浏览数',
    sortable: 'sortable',
    minWidth: 100,
    align: 'right',
  },
  {
    prop: 'publishStatusExplain',
    label: '状态',
    minWidth: 100,
  },
  {
    prop: 'publishTime',
    label: '上架时间',
    sortable: 'sortable',
    minWidth: 150,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '查看详情',
    method: _this.handleCourse,
  },
  {
    label: '销售记录',
    method: _this.handleSellRecord,
  },
]
import TableList from '@/components/TableList'
import { myCourseList } from '@/api/teacher'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      loading: false,
      columns,
      operates,
      searchForm,
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      myCourseList: [],
    }
  },
  mounted() {
    this.handlemyCourseData()
  },
  methods: {
    async handlemyCourseData() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(myCourseList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.myCourseList = res.data.list
      this.pager.total = res.data.total
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.dateTime) {
        queryParams.startTime = queryParams.dateTime[0]
        queryParams.endTime = queryParams.dateTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.handlemyCourseData()
    },
    //查看详情
    handleCourse(row) {
      this.$router.push(`/teacher/myCourseDetail/${row.productId}`)
    },
    //销售记录
    handleSellRecord(row) {
      this.$router.push(`/teacher/myCourseRecordList/${row.productId}`)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handlemyCourseData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handlemyCourseData()
    },
  },
}
</script>
